import React, { useRef, useState } from 'react'
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';
import { MdLocationOn, MdCall, MdEmail } from 'react-icons/md'


function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const TEMPLATE_ID = "template_uv30p0o";
  const SERVICE_ID = "service_fgqci5u";
  const USER_ID = "QgRkeTht_54H1nMdn";

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        SERVICE_ID,
        TEMPLATE_ID,
        form.current,
        USER_ID
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          e.target.reset();

        }
      );

  };

  return (
    <div>
      <div className='h-full pt-11 flex justify-center item-center flex-col px-5'>
        <h1 className='text-center mb-3 text-[42px] font-medium'>Get in Touch</h1>

        <div className='pt-4 flex flex-wrap gap-x-32 gap-y-2 text-secondary-100  md:ml-0 justify-center items-start mb-9'>


          <div className='flex items-center flex-col justify-center text-center'>
            <img src="./asset/address.png" className='h-auto w-[30%] mb-3' alt="" />
            <h3 className=' cursor-pointer text-sm'> <span className='text-3xl ' >Address</span>  <br />
              Kirkos Sub-City <br />
              Yiha City Center, 9th floor <br />
Addis Ababa, Ethiopia.</h3>
          </div>
          <div className='flex items-center flex-col justify-center text-center'>
            <img src="./asset/phone.png" className='h-auto w-[45%] mb-3' alt="" />
            <h3 className=' cursor-pointer text-sm text-black'> <span className='text-3xl ' >Phone</span>  <br />
              <a href="tel:+251912627396">+251912627396</a> <br />
              <a href="tel:+251927211472">+251927211472</a>
              </h3>
          </div>
          <div className='flex items-center flex-col justify-center text-center'>
            <img src="./asset/email.png" className='h-auto w-[30%] mb-3' alt="" />
            <h3 className=' cursor-pointer text-md text-black'> <span className='text-3xl ' >Email</span>  <br />
              <a href="mailto: bisrat@bisratoffice.com">bisrat@bisratoffice.com</a> <br />
            </h3>
          </div>

        </div>
        <div className="bg-primary-100 w-4/5  rounded-xl m-auto flex flex-col md:flex-row justify-center items-center ">
          
          <div className="h-auto w-full md:1/2 rounded-l-xl py-4 px-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15762.109340480181!2d38.7531183!3d9.015565!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85a2a2dc35df%3A0x1f5922d59c8a034b!2sBisrat%20%26%20Partners%20Law%20Office!5e0!3m2!1sen!2set!4v1701445809558!5m2!1sen!2set"
              style={{ border: "0" }} className=" md:ml-3 rounded-md w-full h-64 md:h-[500px]"
              allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

          </div>
          <div className="w-full md:1/2 h-auto rounded-r-xl flex flex-col justify-center items-center p-6 text-white">
            <h2 className="text-center text-[38px]  mb-9 text-white-100">Contact Us</h2>
            <form ref={form} onSubmit={sendEmail} className="w-full flex flex-col justify-between text-black" action="">
              <input value={name} onChange={e => setName(e.target.value)}
                className="indent-3  outline-none rounded-xl bg-gray-100 pl-2 h-10 border-none mb-4 text-base text-gray-900"
                placeholder="Name" type="text" name="from_name" />
              <input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email"
                className="placeholder-gray-400  indent-3 outline-none rounded-xl bg-gray-100 pl-2 h-10 border-none mb-4 text-base"
                type="text" name="reply_to" />
              <textarea value={message} onChange={e => setMessage(e.target.value)}
                placeholder="Message"
                className="indent-3 outline-none rounded-xl bg-gray-100 pl-2 border-none mb-4 text-base"
                name="message" id="" cols="30" rows="8"></textarea>
              <input
                className="indent-3 outline-none rounded-2xl bg-primary-200 pl-2 h-11 cursor-pointer text-white-100 text-2xl w-[50%] mx-auto my-0"
                type="submit" />
            </form>

          </div>
        </div>
        
      </div>
    </div>
  )
}

export default Contact