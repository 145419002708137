import { GraphQLClient, gql } from "graphql-request";

export const graphcms = new GraphQLClient('https://api-us-east-1-shared-usea1-02.hygraph.com/v2/clpb1qdgx4vcn01t89h4tgmwr/master')
    // graphcms.setHeaders({
    //     authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    // });

export const QUERY_INSIGHTS = gql `
    query Insights {
        insights {
        content
        coverPicture {
        url
        }
        postDate
        insightTitle
        author {
        name
        }
        description
        type
        slug
        post{
            raw
        }
        }
    }`

export const QUERY_TEAMS = gql `
    query Teams {
        teams {
    picture {
      url
    }
    title
    name
    description
  }
    }`

export const QUERY_PRACTICES = gql `
    query Practices {
        practices {
    icon {
      url
    }
    title
    description
    }
    }`

export const QUERY_TESTIMONIALS = gql `
    query Testimonials {
         testimonials {
    title
    description
  }
    }`

export const QUERY_HOMEPAGES = gql `
    query HomePages {
         homePages {
    text
    texts
    }
    }`