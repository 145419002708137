import React from 'react'

function PracticesCard({title, img,text}) {
  return (
      <div className='bg-card-100 max-w-[360px] flex flex-col justify-center items-center gap-4 text-center px-6 py-8 rounded-xl'>

          <div className='flex justify-center gap-1'>
              <img className='h-auto w-[23%]' src={img} alt="" />
              <p className='text-2xl font-semibold text-center'>{title}</p>
          </div>

          <p>{text}</p>

      </div>
  )
}

export default PracticesCard