import { useEffect, useState } from 'react';
import { BiPhoneCall, BiRightArrowAlt, BiMenuAltLeft } from 'react-icons/bi'
import { AiFillFacebook, AiOutlineInstagram } from 'react-icons/ai'
import { TbBrandTelegram } from 'react-icons/tb'
import { useParams } from 'react-router-dom';
const Header = ({ home }) => {

  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const {head} = useParams();

  const headerItems = [
    {
      id: 1,
      name: 'Home',
      link: '/',
    },
    {
      id: 2,
      name: 'About',
      link: '/about',
    },
    {
      id: 3,
      name: 'Team',
      link: '/team',
    },
    {
      id: 4,
      name: 'Insight',
      link: '/blog',
    },
    {
      id: 5,
      name: 'Contact Us',
      link: '/contact-us',
    },
  ];

  const menuClickHandler = () => {
    setActive(true);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    console.log(home, "home")
    console.log(head)
  }, [])

  return (
    <header className={`overflow-x-hidden sm:overflow-x-visible w-full bg-transparent h-[10vh] text-gray-100 border-black border-b-[1px] overflow-hidden`}>
      <div className='w-full flex justify-around items-center h-full '>

        <div className='ml-10 sm:ml-0 flex justify-center h-[70%] items-center w-[40%] sm:w-[10%] '>
          <a href='/' className='text-2xl font-bold'>
            <img src={head ?`./asset/logo.png`:`../asset/logo.png`} />
          </a>
        </div>

        <div className='hidden w-[40%] sm:flex justify-around text-black navbar'>
         
          <a href="/" className={`cursor-pointer 'text-black-100' text-[19px] font-medium`}>
              Home
          </a>
              
          <a href="/about" className={`cursor-pointer 'text-black-100' text-[19px] font-medium`}>
            About
          </a>

          <a href="/team" className={`cursor-pointer 'text-black-100' text-[19px] font-medium`}>
            Team
          </a>

          <a href="/practices" className={`cursor-pointer 'text-black-100' text-[19px] font-medium`}>
            Practices
          </a>

          <div class="dropdown">
            <button class="dropbtn">Insight
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <a href="/insights/news">News</a>
              <a href="/insights/updates">Legal Update</a>
              <a href="/insights/blog">Blog</a>
            </div>
          </div> 

          <a href="/contact" className={`cursor-pointer 'text-black-100' text-[21px] font-medium`}>
            Contact
          </a>

        </div>

       


        <div className={`z-10 h-[100vh] w-[80%] bg-primary-100 absolute top-0 transition-all duration-500 ease-in ${active ? "right-0 block" : "-right-[100%] hidden"
          }`}>
          <div className="w-full h-full flex flex-col items-start justify-start p-10 ">
            <BiRightArrowAlt
              onClick={() => setActive(false)}
              color="white"
              size="3rem"
            ></BiRightArrowAlt>
            {/* {headerItems.map((e, i) => ( */}
              <div className="my-5 w-full">
                <a href="/" className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]">
                  Home
                </a>

                <a href="/about" className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]">
                  About
                </a>

                <a href="/team" className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]">
                  Team
                </a>

                <a href="/practices" className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]">
                  Practices
                </a>

              <a href="/contact" className="block text-[21px] text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]">
                Contact
              </a>

              <div class="dropdown">
                  <button class="dropbtn">Insight
                    <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="dropdown-content">
                    <a href="/insights/news">News</a>
                    <a href="/insights/updates">Legal Update</a>
                    <a href="/insights/blog">Blog</a>
                  </div>
                </div>

                <br />

               
              </div>
            {/* ))} */}
            {/*<a href='/contactus' className='block w-full mt-2 text-lg text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]'>Contact Us</a>*/}
            <div className='flex sm:hidden w-[100%] mt-10 justify-around items-center text-white text-base'>

              <a href=''>
                <AiFillFacebook color='white' size='1.6rem' className='cursor-pointer' />
              </a>
              <a href=''>

                <AiOutlineInstagram color='white' size='1.6rem' className='cursor-pointer' />
              </a>
              <TbBrandTelegram color='White' size='1.6rem' className='cursor-pointer' />

            </div>
          </div>
        </div>


        <div className='sm:hidden w-[20%] ml-auto'>
          <BiMenuAltLeft color='black' size='2.5rem' className='cursor-pointer' onClick={menuClickHandler} />
        </div>


      </div>
    </header>
  );
};

export default Header;