import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Insight from './pages/Insight';
import Practices from './pages/Practices';
import Team from './pages/Team';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import InsightDetail from './pages/InsightDetail';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/insights/:type" element={<Insight />} />
        <Route path="/practices" element={<Practices />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/insight/:id" element={<InsightDetail/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
