import React, { useState } from 'react'
import '../pages/style.css';
import { useEffect } from 'react';


function TeamCard({title,name,img,desc}) {

    useEffect(() => {
        const slideInElements = document.querySelectorAll('.slide-in');
        const fadeInElements = document.querySelectorAll('.fade-in');


        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-in-active');
                    observer.unobserve(entry.target);
                }
            });
        });

        slideInElements.forEach((element) => {
            observer.observe(element);
        });
    }, []);

    const [state, setState] = useState(false)
  return (
      <div className='max-w-[300px] slide-in'>
          <img src={img} alt="" />
          <p className='my-2'>{title}</p>
          <p className='my-2 font-semibold text-xl'>{name}</p>
          <p>{desc.length > 150 &&  state == false? desc.substring(0, 150) + "...": desc}</p>
          <div onClick={() => { setState(!state) }} className='w-full flex justify-start items-center mt-7'><a href='#' className='text-white bg-primary-100 text-lg px-7 py-1 rounded-3xl cursor-pointer'>Read {state == false ? "More": "Less"}</a></div>
      </div>
  )
}

export default TeamCard