import React from 'react'
import { useEffect } from 'react';
import './style.css';


function About() {

  useEffect(() => {
    const slideInElements = document.querySelectorAll('.slide-in');
    const fadeInElements = document.querySelectorAll('.fade-in');


    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in-active');
          observer.unobserve(entry.target);
        }
      });
    });

    slideInElements.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  return (
      <div className='bg-primary-100 py-[35px] mb-10 flex flex-col items-center'>
        <div className='max-w-[1300px] felx flex-col items-center justify-center px-5'>
        <div className='felx justify-center items-center text-center w-full mt-8 mb-12'>
        <h1 className='text-white text-5xl font-medium '>About US</h1>
        </div>

        <div className='flex  flex-col md:flex-row gap-4 md:gap-0 flex-wrap justify-between items-center max-w-[800px] text-white text-2xl font-medium mb-10 mx-auto my-0  slide-in'>
            <div className='flex gap-3 items-center justify-center'>
            <img src="./asset/expertise.png" alt="" />
                <h2>Expertise</h2>
            </div>
          <div className='flex gap-3 items-center justify-center'>
            <img src="./asset/clientCentrinc.png" alt="" />
                <h2>Client-Centric</h2>
              </div>
          <div className='flex gap-3 items-center justify-center'>
            <img src="./asset/integrity.png" alt="" />
                <h2>Integrity</h2>
              </div>
        </div>

        <div className='inline md:flex justify-center gap-8 items-start bg-center text-center mt-2 mb-10'>
          <img className='h-auto w-[70%] md:w-[30%] mx-auto md:mx-6 my-8 md:my-0' src="./asset/about us image.png" alt="" />
          <div className='w-full md:w-[45%] text-white text-[17px] text-left'>
                  <p className='text-white text-[17px] mb-5'>Bisrat & Partners Law Office (hereinafter the "Office") is an industrious business law office staffed with distinguished lawyers who specialize in diverse areas of practice allowing it to excel in offering quality and reliable services to clients.

            </p> <p className='mb-5'>Our lawyers` experience in advising and representing local and foreign clients in areas such as contract drafting, litigation, arbitration, mergers and acquisitions, banking, energy and infrastructure, financing, commercial and company law, competition, investment, intellectual property, information and communications technology, employment law, construction, real estate, mining, procurement, and tax and customs, makes us the best choice for our clients.

              </p> <p className='mb-5'>The Office is well versed in handling complex cases and projects.</p>
            </div>
        </div>

      <div className='w-full flex justify-center items-center'><a href='#' className='text-white bg-[#FF6A6A] text-lg px-5 py-1 rounded-md cursor-pointer font-medium'>Meet the Team</a></div>
      </div>
    </div>
  )
}

export default About