import React, { useEffect, useState } from 'react'
import PracticesCard from '../components/PracticesCard'
import './style.css';
import { QUERY_PRACTICES, graphcms } from '../Graphql/Queries';
import ReactLoading from 'react-loading';



function Practices() {
  const [practices, setPractices] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const slideInElements = document.querySelectorAll('.slide-in');
    const fadeInElements = document.querySelectorAll('.fade-in');


    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in-active');
          observer.unobserve(entry.target);
        }
      });
    });

    slideInElements.forEach((element) => {
      observer.observe(element);
    });
  }, []);

  const practice = [
    { title: "Contract and Commercial Law",
      img: "./asset/Contract and Commercial Law.png",
      text: "Our firm handles a wide range of company law matters, including corporate governance, compliance, and legal structuring of businesses. We support clients in establishing and maintaining legally sound and successful companies."
    },
    {
      title: "Competition",
      img: "./asset/Competition.png",
      text: "We specialize in competition law, assisting clients with issues related to antitrust regulations, market competition, and compliance. Our expertise helps businesses navigate complex competition laws and ensure fair practices."
    },
    {
      title: "Investment Law",
      img: "./asset/Investment Law.png",
      text: "Our team provides legal guidance on investment matters, including investment structuring, due diligence, and regulatory compliance. We assist clients in maximizing opportunities and mitigating risks in the investment landscape."
    },
    {
      title: "Company Law",
      img: "./asset/Company Law.png",
      text: "Our firm handles a wide range of company law matters, including corporate governance, compliance, and legal structuring of businesses. We support clients in establishing and maintaining legally sound and successful companies."
    },
    {
      title: "Mergers and Acquisitions",
      img: "./asset/Mergers and Acquisitions.png",
      text: "Our experienced attorneys offer strategic guidance and representation for mergers and acquisitions. We assist with the entire process, ensuring smooth transactions and favorable outcomes."
    },
    {
      title: "Banking and Insurance",
      img: "./asset/Banking and Insurance.png",
      text: "We provide legal services for banking and insurance matters, offering expert advice on regulatory compliance, transactions, and dispute resolution in these industries."
    }, {
      title: "Intellectual Property",
      img: "./asset/Intellectual Property.png",
      text: "We offer comprehensive services in intellectual property law, including trademark registration, patent protection, and copyright matters. Our dedicated attorneys safeguard your intellectual assets and provide effective enforcement strategies."
    }, {
      title: "Employment law",
      img: "./asset/Employment law.png",
      text: "Our firm advises on employment law matters, including contracts, workplace policies, and dispute resolution. We support both employers and employees in navigating complex employment regulations."
    }, {
      title: "Construction and Real Estate",
      img: "./asset/Construction and Real Estate.png",
      text: "We assist clients with legal matters related to construction and real estate projects. From contract negotiation to dispute resolution, our team ensures smooth transactions and protects your interests."
    }, {
      title: "Mining and Energy",
      img: "./asset/Mining and Energy.png",
      text: "Our firm specializes in legal services for the mining and energy sectors. We provide guidance on regulatory compliance, contracts, and dispute resolution, helping clients navigate the unique challenges of these industries."
    }, {
      title: "Tax and Customs",
      img: "./asset/Tax and Customs.png",
      text: "We offer comprehensive tax and customs legal services, providing advice on tax planning, compliance, and dispute resolution. Our experienced team helps clients optimize their tax strategies and navigate customs regulations effectively."
    },
  ]

  useEffect(() => {
    graphcms.request(QUERY_PRACTICES)
      .then(data => {
        setPractices(data.practices.reverse())
        console.log(data.practices)
        setLoading(false);
      }).catch(err => console.log(err));
  }, [])





  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen text-secondary-100'>
        <ReactLoading type="spin" color='#1F6A7B' height={'50px'} width={'50px'} />
      </div>)
  }

  return (
    <div>
      <h1 className='text-center my-14 text-4xl font-medium slide-in'>Some of the Practice Areas <br /> We Focus On</h1>

      <div className='max-w-[1300px] mx-auto my-0 flex flex-wrap justify-center md:justify-left items-center gap-14'>
        {
          practices.slice().reverse().map(p=>(
            <PracticesCard title={p.title} img={p.icon.url} text={p.description}/>
          ))
        }
      </div>

    </div>
  )
}

export default Practices