import React from 'react'

function Footer() {
  return (
    <div className='bg-primary-100 text-white'>
      <div className='max-w-[1300px] mx-auto my-0 mt-16 pt-16'>
      <ul className='flex flex-wrap justify-center md:justify-between gap-x-8  md:gap-x-0 items-center max-w-[650px] mx-auto my-0 text-lg font-medium px-5'>
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
          <li><div class="dropdown">
            <button class="dropbtn">Insight
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-content">
              <a href="/insights/news">News</a>
              <a href="/insights/updates">Legal Update</a>
              <a href="/insights/blog">Blog</a>
            </div>
          </div> </li>
        <li><a href="/team">Teams</a></li>
          <li><a href="practices">Practices</a></li>
          
        <li><a href="contact">Contact</a></li>
      </ul>

        <p className='text-[17px] mt-9 mb-7 text-center font-light border-[#9a9a9a] pb-2 border-b-[1px]'>Kirkos Sub-City Yiha City Center, 9th floor Addis Ababa, Ethiopia</p>

      <div className='flex flex-col md:flex-row gap-y-6 justify-between items-center'>
          <a href="/"><img src="./asset/footer logo.png" alt="" /></a>

          <ul className='flex flex-wrap gap-7'>
            <li><a href="">Terms</a></li>
            <li><a href="">Privacy</a></li>
            <li><a href="">Cookies</a></li>
          </ul>

          <div className='flex flex-wrap gap-7'>
            <img src="./asset/Linkedin.svg" alt="" />
            <img src="./asset/Facebook.svg" alt="" />
            <img src="./asset/Twitter.svg" alt="" />
          </div>

      </div>

        <p className='text-[16px] font-light pt-10 pb-5 text-center'>Copyright © 2024 Bisrat and Partners Law Office</p>

      </div>
    </div>
  )
}

export default Footer