import React, { useState } from 'react'
import { useEffect } from 'react';
import './style.css';
import { QUERY_HOMEPAGES, QUERY_INSIGHTS, QUERY_TESTIMONIALS, graphcms } from '../Graphql/Queries';
import ReactLoading from 'react-loading';
import CountUp from 'react-countup';
import { RichText } from '@graphcms/rich-text-react-renderer';



function Home() {
  const [insights, setinsights] = useState();
  const [testimonials, setTestimonials] = useState();
  const [homePages, setHomePages] = useState();
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);



  // useEffect(() => {
  //   const slideInElements = document.querySelectorAll('.slide-in');
  //   const fadeInElements = document.querySelectorAll('.fade-in');


  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add('slide-in-active');
  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   });

  //   slideInElements.forEach((element) => {
  //     observer.observe(element);
  //   });
  // }, []);

  useEffect(() => {
    graphcms.request(QUERY_INSIGHTS)
      .then(data => {
        setinsights(data.insights.reverse())
        console.log(data.insights)
        // setLoading(false);
      }).catch(err => console.log(err));
      
  }, [])


  useEffect(() => {
    graphcms.request(QUERY_TESTIMONIALS)
      .then(data => {
        setTestimonials(data.testimonials.reverse())
        console.log(data.testimonials)
        // setLoading(false);
      }).catch(err => console.log(err));

  }, [])

  useEffect(() => {
    graphcms.request(QUERY_HOMEPAGES)
      .then(data => {
        setHomePages(data.homePages.reverse())
        console.log(data.homePages)
        setLoading(false);
      }).catch(err => console.log(err));

  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === testimonials?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [testimonials?.length]);
 
  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === testimonials?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? testimonials?.length - 1 : prevIndex - 1
    );
  };

  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen text-secondary-100'>
        <ReactLoading type="spin" color='#1F6A7B' height={'50px'} width={'50px'} />
      </div>)
  }


  return ( 
<>
      <section className='relative h-screen w-full z-0'>
        <img className='absolute inset-0 object-cover h-screen w-full' src="./asset/hero.png" alt="" />

        <div className='absolute top-[50%] left-[30%] md:left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white w-full pl-36 '>
          <h1 className='text-white text-left my-8  text-6xl font-light max-w-[500px] border-primary-200 border-l-8 rounded-md pl-4 '>Your Trusted Partners in Legal Solutions</h1>
          <p className='text-[20px] text-left  max-w-[600px] font-light '>Ethiopian legal powerhouse, excelling in corporate law, contracts, and taxation. Renowned for commitment, expertise in litigation, arbitration, and advisory services.</p> 
          <div className='w-full flex justify-start mt-11 items-center '><a href='/contact' className='text-white bg-[#FF6A6A] text-xl px-6 py-2 rounded-md cursor-pointer font-medium'>Get Started</a></div>

        </div>

     

      </section>
      {/* <div className='flex justify-center mb-[-600px] md:mb-[-150px] absolute bottom-0 left-1/2 transform -translate-x-1/2 '>
        <div className='bg-white rounded-2xl '>
          <ul className='flex flex-wrap gap-9 justify-center items-center text-center text-5xl py-5 px-10'>
            <li className='border-none md:border-[#D9D9D9] border-r-[2px] pr-4'> <CountUp end={100} duration={2} separator="," />+ <br /> <span className='text-2xl'>Clients</span></li>
            <li className='border-none md:border-[#D9D9D9] border-r-[2px] pr-4'> <CountUp end={100} duration={2} separator="," />+ <br /> <span className='text-2xl'>Cases</span></li>
            <li className='border-none md:border-[#D9D9D9] border-r-[2px] pr-4'> <CountUp end={20} duration={2} separator="," />+ <br /> <span className='text-2xl'>Attorneys</span></li>
            <li className=' '> <CountUp end={10} duration={2} separator="," />+ <br /> <span className='text-2xl'>Practice Areas</span></li>
          </ul>
        </div>
      </div> */}
    <div className='max-w-[1300px] mx-auto my-0 px-5'>

      <section>
          <h2 className='text-black text-center my-8 mb-11 text-4xl font-medium mt-[550px] md:mt-28'>Bisrat & Partners Law Office</h2>
      <div className='inline md:flex justify-center gap-8 items-start bg-center text-center mt-2 mb-10'>
          <img className='h-auto w-[70%] md:w-[30%] mx-auto md:mx-6 my-8 md:my-0' src="./asset/law office.png" alt="" />
          <div className='w-full md:w-[50%] text-[17px] text-center md:text-left'>
           
              <p className='mb-5' dangerouslySetInnerHTML={{ __html: homePages[0].texts}} ></p>
            </div>
        </div>
      </section>

      <section>
          <div className='mt-36'>
          <p className='text-black text-center md:text-left text-4xl font-medium border-[#FF6A6A] border-t-4 block md:inline'>Testimonials</p>
          <p className='text-black text-center md:text-left  text-[25px] '>Here's what our clients think of us</p>

        </div>

        <div className='my-10 flex justify-center gap-x-11 items-center'>
            <img src="./asset/arrL.png" className='cursor-pointer  md:inline-block h-fit w-auto' alt="" onClick={handlePrev} />

          <div className='max-w-[450px] bg-[#EAF5F7] py-6 px-3 rounded-xl'>
              <p className='text-[17px] mb-5'>{testimonials[currentIndex].description}</p>
              <p className='text-[19px] mb-5 font-semibold'>{testimonials[currentIndex].title}</p> 
          </div>

         
            <img src="./asset/arrR.png" className='cursor-pointer md:inline-block h-fit w-auto' alt="" onClick={handleNext} />
        </div>

      </section>

      <section className='mt-32'>

          <h2 className='text-black text-center my-26 mb-11 text-4xl font-medium'>Our Practice Areas</h2>

        <div className='flex flex-wrap justify-center gap-x-28 w-full mx-auto mt-0 gap-y-9 items-center'>
          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Contract and Commercial Law.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Contract and Commercial Law</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Competition.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Competition</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Investment Law.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Investment Law</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Company Law.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Company Law</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Mergers and Acquisitions.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Mergers and Acquisitions</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Banking and Insurance.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Banking and Insurance</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Intellectual Property.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Intellectual Property</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Employment law.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Employment law</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Construction and Real Estate.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Construction and Real Estate</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Mining and Energy.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Mining and Energy</p>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <img src="./asset/Tax and Customs.png" alt="" />
            <p className='text-[19px] mb-5 font-medium max-w-[70%] text-center'>Tax and Customs</p>
          </div>
        </div>

      </section>

      <section className='mt-32'>
          <h2 className='text-black text-center my-26 mb-16 text-4xl font-medium '>Latest Insights</h2>

        <div className='flex flex-wrap justify-between items-center  gap-y-20'>

            {insights.slice(0, 3).map(i=>(
              <div className='flex justify-center items-center flex-col max-w-[400px]'>
                <img className='max-w-[350px] h-auto rounded-md' src={i.coverPicture.url} alt="" />
                <p className='text-xl font-medium max-w-[70%] text-center my-4'>{i.insightTitle}</p>
                <a className='' href={`/insight/${i.slug}`}><p className='text-primary-100 cursor-pointer text-[19px] underline font-medium text-left'>Read More</p></a>
              </div>
            ))}
        </div>
      </section>

    </div>
    </>
  )
}

export default Home