import React, { useEffect, useState } from 'react'
import TeamCard from '../components/TeamCard'
import { QUERY_TEAMS, graphcms } from '../Graphql/Queries';
import ReactLoading from 'react-loading';


function Team() {
  const [team, setTeams] = useState();
  const [loading, setLoading] = useState(true);



  const teams = [
    {
      title:"Founder and Principal",
      img: "./asset/bisrat.png",
      name:"Mr. Bisrat Teklu",
      description: `Bisrat Teklu holds an LL.B from Jimma University (2011) with Great Distinction, an LL.M. in Business Law from Addis Ababa University (2016) with Very Great Distinction, and a FIATA Diploma from the Ethiopian Freight Forwarders and Shipping Agents Association (2021) with Very Great Distinction. Additionally, he is a Certified FIATA Diploma Trainer since 2023, accredited by the International Federation of Freight Forwarders.

Bisrat serves as a licensed consultant, attorney-at-law, and lecturer at Addis Ababa University, where he teaches courses in Law of Contracts, Construction Law, and Law of Administrative Contracts. Moreover, he instructs the "Freight Forwarding and Maritime Law" course at the Ethiopian Customs Commission.

With a wealth of experience in litigation and advisory services, Bisrat specializes in contracts, construction, international sale of goods, securities, tax, and customs. Notable companies he has advised and represented include Google LLC, ByteDance, Flamingo Horticulture, Raxio Data Center, Worldpay Group, Afrexim Bank, Adidas, and Safaricom Telecommunications.

Currently, Bisrat is actively involved in advising and representing China Railway 14th Bureau Group Co., Ltd. and Beijing Urban Construction Group in construction contract disputes.

His contributions extend beyond legal practice; Bisrat assisted PricewaterhouseCoopers in developing capital market service providers' licensing and supervision policies in Ethiopia. He also provided legal and contractual advice to various companies in the domains of contracts, commercial law, construction law, securities, mining, arbitration, data protection, corporate law, and tax and customs.`
    },
    {
      title: "Attorney",
      img: "./asset/FILLER-no-headshot.png",
      name: "Binyam Nibret",
      description: `Binyam, an LL.B. graduate from Hawassa University in 2007, has been a licensed attorney since 2011 and holds a position as an LL.M. candidate in Business Law at Addis Ababa University since 2020. Additionally, he obtained a license as a Trademark Agent in 2020.

Starting his legal career in 2007, Binyam joined a Law Office, where he played a pivotal role in advising numerous foreign companies. Notable achievements include representing KBR Overseas Inc. in a significant labor suit and providing legal support to Heineken International BV in its acquisitions in Ethiopia, including the establishment of Heineken Breweries Share Company.

Beyond his law firm responsibilities, Binyam served as a general counsel for MCC Media and Communications Center (the Reporter), a widely circulated private newsletter in Ethiopia.

Binyam's expertise spans various legal domains, advising both local and foreign clients. He has worked alongside prominent lawyers, including Bisrat Teklu, in cases involving companies such as African Mining and Energy Co. Ltd, Razel-BEC, Beijing Urban Construction Group Co. Ltd, Tessy Engineers and Enterprises, GPB Global Resources B.V., and more.

Binyam's extensive client list includes Action Aid International, Heifer International, Give Directly, Adidas, AEC, Afrexim Bank, Alliance Pharma, American Tower Corporation, Bruker Daltonik GmbH, Hain Lifescience SA (Pty) Ltd, ByteDance entity, Apple Distribution International, SIEMENS Pty Ltd (Ethiopia Branch), Flamingo Horticulture Kenya Limited, Raxio Data Center PLC, and Worldpay Group.

Currently, as a member of Bisrat & Partners Law Office, Binyam focuses on construction, investment, corporate law, merger and acquisition, competition law, employment law, and intellectual property. His dedication is evident in representing various prominent investors in arbitration and litigation, showcasing his prowess in the legal arena.
`
    },
    {
      title: "Attorney",
      img: "./asset/Beliyu.png",
      name: "Mrs. Beliyu Estifanos",
      description: `LL.B, Jimma University (2011)
 
As an attorney, Beliyu has extensive experience in litigation and advisory services focusing on contracts, construction, employment relations, customs, and taxation. She advised and represented prominent companies such as Beijing Urban Construction Group Ltd., China Railway 14th Bureau Group Co., Ltd., and Altrade International Business. Beliyu’s experience in construction disputes includes the following:
•	Advising and representing China Railway 14th Bureau Group Co., Ltd. in a FIDIC-based construction contract dispute.
•	Advising and representing Beijing Urban Construction Group on FIDIC-based design-build construction contract disputes.
•	Advised and represented Altrade International PLC on construction, contracts and employment law advisory and litigation on Hawassa Industrial Park, Mekelle Industrial Park, and Debre Birhan Industrial Park projects;
Currently, Beliyu advises and represents companies in the areas of employment relations, construction, corporate law, and tax and customs.
`
    },
    {
      title: "Attorney",
      img: "./asset/FILLER-no-headshot.png",
      name: "Mr. Ermias Mamo",
      description: `LL.B, Addis Ababa University
LL.M. in Business Law, Addis Ababa University
 
 
Ermias started his professional career at Addis Saving and Credits Association as an in-house lawyer. Moreover, before joining the Office, Ermias worked at the Federal First Instance Court of the Federal Democratic Republic of Ethiopia as an assistant judge. During his stay at the Federal First Instance Court, Ermias worked mainly on commercial and insurance benches.
Ermias currently advises and represents companies in insurance, corporate law, and commercial law.
 
I.	
`
  }
  ]

  useEffect(() => {
    graphcms.request(QUERY_TEAMS)
      .then(data => {
        setTeams(data.teams.reverse())
        console.log(data.teams)
        setLoading(false);
      }).catch(err => console.log(err));
  }, [])





  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen text-secondary-100'>
        <ReactLoading type="spin" color='#1F6A7B' height={'50px'} width={'50px'} />
      </div>)
  }


  return (
    <div className='max-w-[1300px] mx-auto my-0'>
      <h1 className='text-center my-14 text-4xl font-medium'>Meet the Team</h1>
      <div className='flex flex-wrap gap-x-12 justify-center md:justify-between gap-y-24'>

      {
          team.slice().reverse().map(t=>(
          <TeamCard title={t.title} name={t.name} desc={t.description} img={t.picture.url}/>
        ))
      }
        

      </div>
    </div>
  )
}

export default Team